import React from "react"
import ReactDOM from "react-dom"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel"
import Trans from "./Trans"

const Feedback = () => {
  return (
    <div>
      <CarouselProvider
        className="max-w-7xl px-2 relative mx-auto max-h-1500 h-auto xl:h-auto"
        naturalSlideWidth={100}
        naturalSlideHeight={180}
        totalSlides={11}
        isPlaying={true}
        interval={4000}
      >
        <Slider className="">
          <Slide index={0}>
            <div className="px-3 md:px-9 relative grid justify-center lg:justify-start items-start lg:items-start lg:flex text-purple-100">
              <div className="w-full lg:w-4/12 mx-auto lg:px-4">
                <div className="flex justify-center transform scale-75 xl:scale-100">
                  <img
                    class="rounded-full border-2 border-orange"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/AF.webp"
                    height="180"
                    width="180"
                    alt="speaker name"
                  />
                </div>
                <h2 className="text-center pt-3 lg:pt-8 pb-1 text-2xl text-gray-500">
                  Aiman Fatima
                </h2>
                <p className="text-center lg:w-9/12 mx-auto text-gray-500">
                  Manager - Employee Relations
                </p>
              </div>
              <div className="w-full lg:w-8/12 pt-6 xl:pt-0 px-4">
                <div className="mt-0 mb-5">
                  <img
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2023/01/quote.webp"
                    height="70"
                    width="70"
                    alt="quote-icon"
                  />
                </div>
                <p className="italic" style={{ lineHeight: "2rem" }}>
                  Vantage Rewards is the first online-based rewards and
                  recognition platform that we’ve implemented. The point-based
                  rewarding system has made tracking of rewards and it’s data
                  more manageable. The report feature has been helpful in
                  understanding the budget and reward allocations. It has also
                  given us the option of analyzing where we are in terms of
                  recognizing our employees. Every organization, like ours,
                  would certainly benefit from using the platform.
                </p>
                <div className="pt-3 lg:pt-5 lg:pl-1">
                  <img
                    decoding="async"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/Merilytics.webp"
                    height="130"
                    width="130"
                    alt="Merilytics Logo"
                  />
                </div>
              </div>
            </div>
          </Slide>
          <Slide index={1}>
            <div className="px-3 md:px-9 relative grid justify-center lg:justify-start items-start lg:items-start lg:flex text-purple-100">
              <div className="w-full lg:w-4/12 mx-auto lg:px-4">
                <div className="flex justify-center transform scale-75 xl:scale-100">
                  <img
                    class="rounded-full border-2 border-orange"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/SG.webp"
                    height="180"
                    width="180"
                    alt="speaker name"
                  />
                </div>
                <h2 className="text-center pt-3 lg:pt-8 pb-1 text-2xl text-gray-500">
                  Samir Gadgil
                </h2>
                <p className="text-center text-gray-500">
                  Vice President and HR Head
                </p>
              </div>
              <div className="w-full lg:w-8/12 pt-6 xl:pt-0 px-4">
                <div className="mt-0 mb-5">
                  <img
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2023/01/quote.webp"
                    height="70"
                    width="70"
                    alt="quote-icon"
                  />
                </div>
                <p className="italic" style={{ lineHeight: "2rem" }}>
                  {" "}
                  Wipro Advantage (powered by Vantage Circle) was launched last
                  year to provide excusive privileges to our employees pan
                  India. The feedback received from the employees has been very
                  positive and we feel it is an amazing value proposition for
                  our employees.
                </p>
                <div className="pt-3 lg:pt-5 lg:pl-1">
                  <img
                    decoding="async"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/Wipro.webp"
                    height="180"
                    width="180"
                    alt="Wipro Logo"
                  />
                </div>
              </div>
            </div>
          </Slide>
          <Slide index={2}>
            <div className="px-3 md:px-9 relative grid justify-center lg:justify-start items-start lg:items-start lg:flex text-purple-100">
              <div className="w-full lg:w-4/12 mx-auto lg:px-4">
                <div className="flex justify-center transform scale-75 xl:scale-100">
                  <img
                    class="rounded-full border-2 border-orange"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/SK.webp"
                    height="180"
                    width="180"
                    alt="speaker name"
                  />
                </div>
                <h2 className="text-center pt-0 xl:pt-8 pb-1 text-2xl text-gray-500">
                  Swati Kohli
                </h2>
                <p className="text-center text-gray-500">Lead HR</p>
              </div>
              <div className="w-full lg:w-8/12 pt-8 xl:pt-0 px-4">
                <div className="mt-0 mb-5">
                  <img
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2023/01/quote.webp"
                    height="70"
                    width="70"
                    alt="quote-icon"
                  />
                </div>
                <p className="italic" style={{ lineHeight: "2rem" }}>
                  {" "}
                  Vantage Circle has helped us revamp our Rewards process
                  entirely and gave us the freedom to choose from various
                  options available online as per the individual specific needs.
                  The online platform has helped boost morale for the employees
                  as part of Escalon Family even during COVID-19. It would not
                  have been possible without such a platform when the employees
                  were scattered across the country. It helped us big time and
                  has been our preferred option for all our employee motivation
                  activities. We are glad to be associated with Vantage Circle
                  and look forward to a long-term partnership with them.
                </p>
                <div className="pt-3 lg:pt-5 lg:pl-1">
                  <img
                    decoding="async"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/escalon.webp"
                    height="130"
                    width="130"
                    alt="Escalon Logo"
                  />
                </div>
              </div>
            </div>
          </Slide>
          <Slide index={3}>
            <div className="px-3 md:px-9 relative grid justify-center lg:justify-start items-start lg:items-start lg:flex text-purple-100">
              <div className="w-full lg:w-4/12 mx-auto lg:px-4">
                <div className="flex justify-center transform scale-75 xl:scale-100">
                  <img
                    class="rounded-full border-2 border-orange"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/NS.webp"
                    height="180"
                    width="180"
                    alt="speaker name"
                  />
                </div>
                <h2 className="text-center pt-3 lg:pt-8 pb-1 text-2xl text-gray-500">
                  Nitin Sansare
                </h2>
                <p className="text-center text-gray-500">
                  Head – Performance Management, Compensation and Benefits
                </p>
              </div>
              <div className="w-full lg:w-8/12 pt-6 xl:pt-0 px-4">
                <div className="mt-0 mb-5">
                  <img
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2023/01/quote.webp"
                    height="70"
                    width="70"
                    alt="quote-icon"
                  />
                </div>
                <p className="italic" style={{ lineHeight: "2rem" }}>
                  {" "}
                  Building a highly engaged workforce is integral to Tata
                  Motors' vision. One of the important pillars for fostering
                  engagement is to celebrate contributions and milestones. This
                  can be best achieved by real-time recognition and celebration.
                  Vantage Circle has been a wonderful partner as we developed
                  our Digital Rewards &amp; Recognition program - Accolades. We
                  value our collaboration with Team Vantage and look forward to
                  working together on offering great experience to our
                  employees.
                </p>
                <div className="pt-3 lg:pt-5 lg:pl-1">
                  <img
                    decoding="async"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/Tatamotors.webp"
                    height="160"
                    width="160"
                    alt="Tatamotors Logo"
                  />
                </div>
              </div>
            </div>
          </Slide>
          <Slide index={4}>
            <div className="px-3 md:px-9 relative grid justify-center lg:justify-start items-start lg:flex text-purple-100">
              <div className="w-full lg:w-4/12 mx-auto lg:px-4">
                <div className="flex justify-center transform scale-75 xl:scale-100">
                  <img
                    class="rounded-full border-2 border-orange"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/BS.webp"
                    height="180"
                    width="180"
                    alt="speaker name"
                  />
                </div>
                <h2 className="text-center pt-3 lg:pt-8 pb-1 text-2xl text-gray-500">
                  Bani Sodhi
                </h2>
                <p className="text-center text-gray-500">Human Resource</p>
              </div>
              <div className="w-full lg:w-8/12 pt-6 xl:pt-0 px-4">
                <div className="mt-0 mb-5">
                  <img
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2023/01/quote.webp"
                    height="70"
                    width="70"
                    alt="quote-icon"
                  />
                </div>
                <p className="italic" style={{ lineHeight: "2rem" }}>
                  {" "}
                  We really like the way that Vantage Circle has created a wave
                  of family spirit within Adecco and the way the platform serves
                  all our colleagues across the branches and it gets everyone
                  together. The rewards points given to our employees and they
                  are free to choose wherever they want to spend their points,
                  which is a big win for us. Vantage Circle has done a great job
                  for Adecco and I would definitely recommend it for other
                  companies as well.
                </p>
                <div className="pt-3 lg:pt-5 lg:pl-1">
                  <img
                    decoding="async"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/Adecco.webp"
                    height="130"
                    width="130"
                    alt="Adecco Logo"
                  />
                </div>
              </div>
            </div>
          </Slide>
          <Slide index={5}>
            <div className="px-3 md:px-9 relative grid justify-center lg:justify-start items-start lg:flex text-purple-100">
              <div className="w-full lg:w-4/12 mx-auto lg:px-4">
                <div className="flex justify-center transform scale-75 xl:scale-100">
                  <img
                    class="rounded-full border-2 border-orange"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/RJ.webp"
                    height="180"
                    width="180"
                    alt="speaker name"
                  />
                </div>
                <h2 className="text-center pt-3 lg:pt-8 pb-1 text-2xl text-gray-500">
                  Ritesh Joseph
                </h2>
                <p className="text-center text-gray-500">HR Business Partner</p>
              </div>
              <div className="w-full lg:w-8/12 pt-6 xl:pt-0 px-4">
                <div className="mt-0 mb-5">
                  <img
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2023/01/quote.webp"
                    height="70"
                    width="70"
                    alt="quote-icon"
                  />
                </div>
                <p className="italic" style={{ lineHeight: "2rem" }}>
                  {" "}
                  Vantage Circle has transformed our previous manual rewarding
                  process. With the ease of use, streamlined rewards
                  distribution, and flexibility of choosing rewards, our TAT
                  time has reduced considerably. Vantage Circle’s admin
                  dashboard gives us incredible insights into data-backed
                  engagement patterns. We have seen a chain reaction of enhanced
                  work performance in all our teams. It really is the perfect
                  solution to hassle-free rewards and recognition.
                </p>
                <div className="pt-3 lg:pt-5 lg:pl-1">
                  <img
                    decoding="async"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/ischoolconnect.webp"
                    height="170"
                    width="170"
                    alt="Infosys Logo"
                  />
                </div>
              </div>
            </div>
          </Slide>
          <Slide index={6}>
            <div className="px-3 md:px-9 relative grid justify-center lg:justify-start items-start lg:flex text-purple-100">
              <div className="w-full lg:w-4/12 mx-auto lg:px-4">
                <div className="flex justify-center transform scale-75 xl:scale-100">
                  <img
                    class="rounded-full border-2 border-orange"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/SB-1-1.webp"
                    height="180"
                    width="180"
                    alt="speaker name"
                  />
                </div>
                <h2 className="text-center pt-3 lg:pt-8 pb-1 text-2xl text-gray-500">
                  Shruthi Bopaiah
                </h2>
                <p className="text-center text-gray-500">
                  AVP and Head of Internal Communication
                </p>
              </div>
              <div className="w-full lg:w-8/12 pt-6 xl:pt-0 px-4">
                <div className="mt-0 mb-5">
                  <img
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2023/01/quote.webp"
                    height="70"
                    width="70"
                    alt="quote-icon"
                  />
                </div>
                <p className="italic" style={{ lineHeight: "2rem" }}>
                  {" "}
                  We are very happy to be associated with InfyGold+, a part of
                  Rewards and Recognition Program at Infosys and we run this
                  program with Vantage Circle. It has been awesome to have
                  worked with Vantage Circle and the team has helped us provide
                  great experiences to our employees through a single platform,
                  which is easy to use, provides a gamut of options and most
                  importantly, is available on personal smartphones throught the
                  app.
                </p>
                <div className="pt-3 lg:pt-5 lg:pl-1">
                  <img
                    decoding="async"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/Infosys.webp"
                    height="130"
                    width="130"
                    alt="Infosys Logo"
                  />
                </div>
              </div>
            </div>
          </Slide>
          <Slide index={7}>
            <div className="px-3 md:px-9 relative grid justify-center lg:justify-start items-start lg:flex text-purple-100">
              <div className="w-full lg:w-4/12 mx-auto lg:px-4">
                <div className="flex justify-center transform scale-75 xl:scale-100">
                  <img
                    class="rounded-full border-2 border-orange"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/PG.webp"
                    height="180"
                    width="180"
                    alt="speaker name"
                  />
                </div>
                <h2 className="text-center pt-0 xl:pt-8 pb-1 text-2xl text-gray-500">
                  Pankaj Gupta
                </h2>
                <p className="text-center text-gray-500">Associate Manager</p>
              </div>
              <div className="w-full lg:w-8/12 pt-6 xl:pt-0 px-4">
                <div className="mt-0 mb-5">
                  <img
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2023/01/quote.webp"
                    height="70"
                    width="70"
                    alt="quote-icon"
                  />
                </div>
                <p className="italic" style={{ lineHeight: "2rem" }}>
                  {" "}
                  Vantage Circle has made our Rewarding and Recognition process
                  seamless, and we are now empowered to instantly recognize and
                  celebrate great work. Giving the employees monetary awards or
                  acknowledging other internal accomplishments has never been
                  easier. The point-based reward gives employees the flexibility
                  to choose their gift cards from various online/retail stores.
                  It encourages employee motivation by showcasing their
                  achievements of milestones and recognitions via social
                  leaderboard on the platform. It also helps us build employee
                  satisfaction in the long run and a culture where recognition
                  is never delayed.
                </p>
                <div className="pt-3 lg:pt-5 lg:pl-1">
                  <img
                    decoding="async"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/TAdigital.webp"
                    height="130"
                    width="130"
                    alt="TAdigital Logo"
                  />
                </div>
              </div>
            </div>
          </Slide>
          <Slide index={8}>
            <div className="px-3 md:px-9 relative grid justify-center lg:justify-start items-start lg:flex text-purple-100">
              <div className="w-full lg:w-4/12 mx-auto lg:px-4">
                <div className="flex justify-center transform scale-75 xl:scale-100">
                  <img
                    class="rounded-full border-2 border-orange"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/NK.webp"
                    height="180"
                    width="180"
                    alt="speaker name"
                  />
                </div>
                <h2 className="text-center pt-3 lg:pt-8 pb-1 text-2xl text-gray-500">
                  Nikita Kamtekar
                </h2>
                <p className="text-center text-gray-500">
                  Internal Communications Manager
                </p>
              </div>
              <div className="w-full lg:w-8/12 pt-6 xl:pt-0 px-4">
                <div className="mt-0 mb-5">
                  <img
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2023/01/quote.webp"
                    height="70"
                    width="70"
                    alt="quote-icon"
                  />
                </div>
                <p className="italic" style={{ lineHeight: "2rem" }}>
                  {" "}
                  The Rewards and Recognition program we had before was very
                  different from what Vantage Circle offers. After Vantage
                  Circle, everything became a lot easier and structured. When we
                  launched the program we saw an engagement of 55-65% which is
                  quite a good according to me. Right now our plan is to improve
                  our internal communication around it. The most popular feature
                  here is the badges, people are really enjoying giving each
                  other badges.
                </p>
                <div className="pt-3 lg:pt-5 lg:pl-1">
                  <img
                    decoding="async"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/Tatarealty.webp"
                    height="130"
                    width="130"
                    alt="TataRealty Logo"
                  />
                </div>
              </div>
            </div>
          </Slide>
          <Slide index={9}>
            <div className="px-3 md:px-9 relative grid justify-center lg:justify-start items-start lg:flex text-purple-100">
              <div className="w-full lg:w-4/12 mx-auto lg:px-4">
                <div className="flex justify-center transform scale-75 xl:scale-100">
                  <img
                    class="rounded-full border-2 border-orange"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/RA.webp"
                    height="180"
                    width="180"
                    alt="speaker name"
                  />
                </div>
                <h2 className="text-center pt-3 lg:pt-8 pb-1 text-2xl text-gray-500">
                  Roshan Andrews
                </h2>
                <p className="text-center text-gray-500">
                  HR - Employee Experience & Internal Communications
                </p>
              </div>
              <div className="w-full lg:w-8/12 pt-6 xl:pt-0 px-4">
                <div className="mt-0 mb-5">
                  <img
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2023/01/quote.webp"
                    height="70"
                    width="70"
                    alt="quote-icon"
                  />
                </div>
                <p className="italic" style={{ lineHeight: "2rem" }}>
                  {" "}
                  Vantage Rewards have been a part of our renewed efforts to
                  establish a culture of recognition by giving our workforce the
                  flexibility to choose their own rewards. The Instant
                  recognition feature makes it convenient for us to reward good
                  work immediately. From looking forward to being incentivized,
                  having the choice of multiple reward options, and the
                  user-friendly interface; has caused our people to get more
                  motivated than ever.
                </p>
                <div className="pt-3 lg:pt-5 lg:pl-1">
                  <img
                    decoding="async"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/Arvind.webp"
                    height="130"
                    width="130"
                    alt="Arvind Logo"
                  />
                </div>
              </div>
            </div>
          </Slide>
          <Slide index={10}>
            <div
              className="px-3 relative grid justify-center lg:justify-start items-start lg:flex text-purple-100"
              style={{ height: "400px" }}
            >
              <div className="w-full lg:w-4/12 mx-auto lg:px-4">
                <div className="flex justify-center transform scale-75 xl:scale-100">
                  <img
                    class="rounded-full border-2 border-orange"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/VM.webp"
                    height="140"
                    width="180"
                    alt="speaker name"
                  />
                </div>
                <h2 className="text-center lg:pt-8 pb-1 text-2xl text-gray-500">
                  Vimmi Mehta
                </h2>
                <p className="text-center text-gray-500 pb-8 lg:pb-0">
                  Head – R&R & Quality Improvement
                </p>
              </div>
              <div className="w-full lg:w-8/12 px-2 lg:px-4">
                <div className="mt-0 mb-5">
                  <img
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2023/01/quote.webp"
                    height="70"
                    width="70"
                    alt="quote-icon"
                  />
                </div>
                <p className="italic" style={{ lineHeight: "1.6rem" }}>
                  {" "}
                  Vantage Circle Platform was implemented to support existing
                  Reward & Recognition Process. Vantage Reward Points can be
                  availed easily by Employees providing complete freedom to
                  choose from various bouquet of Services and Online options
                  available as per the individual choices (have personalized
                  experience). The online platform made it easier to have
                  instant gratification possible. This Platform is now widely
                  used across all the Functions / Locations. Reward Points are
                  also being given for any Competitions or driving New
                  Initiatives with Spot Prizes given to the Winners. Vantage
                  Reward Points helped in boosting Employees morale even during
                  the pandemic situation. We look forward to a long term
                  partnership with Vantage Circle exploring other features to
                  further enhance R&R Function at Tata Power.
                </p>
                <div className="pt-3 lg:pt-5 lg:pl-1">
                  <img
                    decoding="async"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/Tatapower.webp"
                    height="140"
                    width="140"
                    alt="Tatapower Logo"
                  />
                </div>
              </div>
            </div>
          </Slide>
        </Slider>
        {/* <div className="transform -translate-y-36 md:translate-y-0 w-full xl:w-4/12 mx-auto flex justify-start items-center"> */}
        <div className="w-full xl:w-4/12 mx-auto flex justify-center xl:justify-start items-center">
          <DotGroup className="w-full py-5" id="dots" />
        </div>
      </CarouselProvider>
    </div>
  )
}
const renderfeedbackCarousel = () => {
  if (typeof window !== "undefined") {
    let id = document.querySelector("#feedbackCarousel")
    if (id != null) {
      ReactDOM.render(<Feedback />, id)
    }
  }
}

setTimeout(renderfeedbackCarousel, 100)
export default Feedback
